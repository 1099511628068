.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

html {
  --mat-sys-on-surface: initial;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-sys-background, transparent));
  color: var(--mat-app-text-color, var(--mat-sys-on-background, inherit));
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

html {
  --mat-sys-on-surface: initial;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-sys-background, transparent));
  color: var(--mat-app-text-color, var(--mat-sys-on-background, inherit));
}