/* https://stackoverflow.com/questions/71384038/many-tailwind-css-classes-doesn%C2%B4t-work-on-my-angular-12-project */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '~vanilla-cookieconsent/dist/cookieconsent.css';
@layer base {
  p {
    @apply text-xs;
  }
}

html {
  font-size: 16px;
}
/* Causes thick borders randomly */
.outline {
  outline-style: inherit;
}
cdk-dialog-container {
  outline: none;
}

a {
  color: #0078d4;
}
